import React, { useEffect, useRef } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Collapse = styled(
  ({
    isOpen,
    children,
    className,
  }: {
    isOpen: boolean;
    className?: string;
    children: React.ReactNode;
  }) => {
    const elementRef = useRef<any>(null);

    useEffect(() => {
      const element = elementRef.current;
      if (element) {
        if (isOpen) {
          element.classList.add("is-open");
        } else {
          element.classList.remove("is-open");
        }
      }
    }, [isOpen]);

    return (
      <div ref={elementRef} className={className}>
        {children}
      </div>
    );
  }
)`
  position: absolute;
  top: 100%;
  width: 100%;
  align-items: center;
  flex-grow: 1;
  flex-basis: 100%;
  flex-direction: column;
  z-index: 100;
  background-color: ${({ theme }) => theme.themeColor};
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s;

  &.is-open {
    max-height: 500px;
    transition: max-height 0.5s;
  }

  ${media.md`
  width: auto;
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
  transition: max-height 0s;

  position: relative;
  top: 0;

      display: flex;
      flex-basis: auto;
      flex-direction: row;
  `};
`;

export default Collapse;
