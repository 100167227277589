import color from "color";
interface ThemeVariables {
  titleFont: {
    family: string;
  };
  headerFont: {
    family: string;
  };
  bodyFont: {
    family: string;
    color: string;
  };
  themeColor: string;
  primaryColor: string;
  secondaryColor: string;
  baseFontSizePixels: number;
  baseLineHeight: number;
  baseMargin: number;
  heroImageHeight: string;
  heroImageMinHeight: string;
  parallaxStrengthMobile: number;
  parallaxStrengthDesktop: number;
}

interface Theme extends ThemeVariables {
  semiTransparentBackgroundColor: string;
  primaryColorHover: string;
  secondaryColorHover: string;
  titleFont: {
    family: string;
    color: string;
  };
  headerFont: {
    family: string;
    color: string;
  };
  bodyFont: {
    family: string;
    color: string;
  };
  menuItemActiveColor: string;
  menuItemHoverColor: string;
  boxColor: string;
}

const themeDefaults = {
  titleFont: { family: "'Grandstander', sans-serif" },
  headerFont: { family: "'Grandstander', sans-serif" },
  bodyFont: {
    family: "'Monserrat', Tahoma, Geneva, Verdana, sans-serif",
    color: "#343a40",
  },
  headerColor: "#fff",
  themeColor: "#10bc18",
  primaryColor: "#ff9a18",
  secondaryColor: "#7062a7",
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  baseFontSizePixels: 16, // px
  baseLineHeight: 1.7,
  baseMargin: 2.1,
  titleLineHeight: 1.5,
  heroImageHeight: "95vh",
  heroImageMinHeight: "350px",
  parallaxStrengthMobile: 200,
  parallaxStrengthDesktop: 350,
};

export const gridTheme = {
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 24,
  },
  col: {
    padding: 24,
  },
  container: {
    padding: 24,
    maxWidth: {
      xxl: 1141,
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const createTheme = (themeVars: ThemeVariables): Theme => ({
  ...themeVars,
  semiTransparentBackgroundColor: color(themeVars.primaryColor)
    .darken(0.3)
    .alpha(0.8)
    .toString(),
  primaryColorHover: color(themeVars.primaryColor).darken(0.2).toString(),
  secondaryColorHover: color(themeVars.secondaryColor).darken(0.2).toString(),
  titleFont: {
    ...themeVars.titleFont,
    color: color(themeVars.primaryColor).darken(0.2).toString(),
  },
  headerFont: {
    ...themeVars.headerFont,
    color: color(themeVars.themeColor).darken(0.2).toString(),
  },
  menuItemActiveColor: themeVars.primaryColor,
  menuItemHoverColor: color(themeVars.primaryColor)
    .darken(0.2)
    .alpha(0.8)
    .toString(),
  boxColor: color(themeVars.primaryColor).lighten(0.7).toString(),
});

export const theme = createTheme(themeDefaults);
