function addSlash(slug?: string) {
  if (!slug) {
    return "/";
  }
  if (!slug.startsWith("/")) {
    return `/${slug}`;
  }
  return slug;
}

function removeTrailingSlash(slug: string) {
  if (slug.length === 1 || !slug.endsWith("/")) {
    return `${slug}`;
  }
  return slug.substring(0, slug.length - 1);
}

export function normalizePath(slug?: string) {
  return removeTrailingSlash(addSlash(slug));
}
