import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

function PreloadHeroImages() {
  const data = useStaticQuery(graphql`
    query PreloadImagesQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/(pages|home)/" } }
        sort: { order: ASC, fields: [frontmatter___position] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              backgroundImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    transformOptions: { fit: COVER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const allImageProps = data.allMdx.edges.map(
    ({ node }: { node: any }) =>
      node.frontmatter.backgroundImage.childImageSharp.gatsbyImageData.images
        .fallback
  );

  return (
    <Helmet>
      {allImageProps.map(({ src, srcSet, sizes }: any) => (
        <link
          key={src}
          rel="preload"
          as="image"
          href={src}
          imageSrcSet={srcSet}
          imagesizes={sizes}
        />
      ))}
    </Helmet>
  );
}

export default PreloadHeroImages;
