import { globalHistory } from "@reach/router";
import { useEffect } from "react";

export function useRouteChanged(callback: () => void) {
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      action === "PUSH" && callback();
    });
  }, [callback]);
}
