import React from "react";
import GlobalStyle from "../../styled-components/global-style";
import { theme, gridTheme } from "../../styled-components";
import { GridThemeProvider } from "styled-bootstrap-grid";
import styled, { ThemeProvider } from "styled-components";
import Footer from "./footer";
import Navbar from "./navbar";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { normalizePath } from "../../utils/normalize-path";
import PreloadHeroImages from "../preload-hero-images";
import SEO from "../seo";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  perspective: 8px;
  perspective-origin: 0%;
`;

const Layout = ({
  location,
  children,
}: {
  location: any;
  children: React.ReactNode;
}) => {
  const data = useStaticQuery(graphql`
    query TitleQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/(pages|home)/" } }
        sort: { order: ASC, fields: [frontmatter___position] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              menuItemLabel
              title
              description
              backgroundImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    transformOptions: { fit: COVER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const currentPath = normalizePath(location.pathname);

  const page = data.allMdx.edges
    .map(({ node }: any) => node.frontmatter)
    .find(({ slug }: any) => currentPath === normalizePath(slug));

  const title = page ? page.title : undefined;
  const description = page ? page.description : undefined;
  const image =
    page?.backgroundImage?.childImageSharp?.gatsbyImageData?.images?.fallback
      ?.src;

  return (
    <LayoutWrapper>
      <PreloadHeroImages />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SEO title={title} description={description} image={image} />
        <Helmet>
          <meta charSet="utf-8" />
        </Helmet>
        <GridThemeProvider gridTheme={gridTheme}>
          <>
            <Navbar location={location} />
            {children}
            <Footer />
          </>
        </GridThemeProvider>
      </ThemeProvider>
    </LayoutWrapper>
  );
};

export default Layout;
