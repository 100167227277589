import React from "react";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { Helmet } from "react-helmet";
import { media } from "styled-bootstrap-grid";

const Styles = createGlobalStyle`

${normalize}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  
  font-family: ${(props) => props.theme.bodyFont.family};
  font-size: ${({ theme }) => theme.baseFontSizePixels}px;
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

html,
body,
#___gatsby,
#gatsby-focus-wrapper {
  height: 100%;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;

  line-height: ${({ theme }: any) => theme.baseLineHeight};
  color: ${({ theme }) => theme.bodyFont.color};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: ${(props) => props.theme.headerFont.color};
    font-family: ${(props) => props.theme.headerFont.family};
    line-height: ${(props) => props.theme.titleLineHeight};
    margin-bottom: ${(props) => props.theme.baseMargin * 0.5}rem;
    margin-top: 0;
  }

  h1 {
    font-size: ${({ theme }) => theme.baseFontSizePixels * 2.2}px;
  }

  h2 {
    font-size: ${({ theme }) => theme.baseFontSizePixels * 1.5}px;
  }

  h3 {
    font-size: ${({ theme }) => theme.baseFontSizePixels * 1.3}px;
  }

  h4 {
    font-size: ${({ theme }) => theme.baseFontSizePixels * 1.1}px;
  }

  h5 {
    font-size: ${({ theme }) => theme.baseFontSizePixels}px;
  }

  p, ul, ol {
    margin-bottom: ${(props) => props.theme.baseMargin}rem;
    margin-top: 0;
  }

  // Hard code small font-awesome icon widths to avoid flash of unstyled content
  .svg-inline--fa.fa-w-16 {
    width: 1em;
  }

  blockquote {

    width: 100%;
    border-left: ${({ theme }) => theme.baseMargin / 4}rem solid ${({
  theme,
}) => theme.primaryColor};
    margin-top: 0;
    margin-left: 0;
    margin-bottom: ${({ theme }) => theme.baseMargin}rem;
    padding: ${({ theme }) => theme.baseMargin / 2}rem;
    > * {
      margin-bottom: 0;
    }
  }

  ${media.md`
    blockquote {
      width: auto;
      margin-left: ${({ theme }) => theme.baseMargin * (3 / 4)}rem;
      font-size: ${({ theme }) => theme.baseFontSizePixels * 1.2}px;
    }
  `}


}
`;

const GlobalStyle = () => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Grandstander:wght@600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Styles />
  </>
);

export default GlobalStyle;
