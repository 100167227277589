import { Link } from "gatsby";
import * as React from "react";
import { Container, media } from "styled-bootstrap-grid";
import styled from "styled-components";

const StyledFooter = styled.footer`
  bottom: 0;
  width: 100%;
  height: 100px;
  margin-top: auto;
  background-color: ${({ theme }) => theme.themeColor};
  color: ${({ theme }) => theme.headerColor};

  ${media.md`
      height: 60px;
`};
`;

const FooterText = styled.p`
  margin-bottom: 0;
  font-size: 90%;
  font-weight: 300;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: ${({ theme }) => theme.baseMargin}rem;
  line-height: ${({ theme }) => theme.baseLineHeight};
  overflow: hidden;

  ${media.md`
      padding-top: ${({ theme }: any) => theme.baseMargin}rem;
`};
`;

const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterText>
        Copyright 2021 <Link to="/">Shiremoor Adventure Playground Trust</Link> | All
        rights reserved |
      </FooterText>
    </Container>
  </StyledFooter>
);

export default Footer;
