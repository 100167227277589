import React from "react";
import logo from "../images/shiremoor-bug.svg";
import styled from "styled-components";
import { Link } from "gatsby";
import { media } from "styled-bootstrap-grid";

const LogoLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin-right: 1rem;
`;

const LogoImage = styled.img`
  margin-top: 6px;
  margin-bottom: 6px;
  height: 60px;
  margin-right: 0;
  ${media.md`
  height: 100px;
  position: relative;
  left: -15px;
  `}
`;

const H1 = styled.h1`
  color: ${({ theme }) => theme.headerColor};
  font-size: 20px;
  margin-top: ${({ theme }) => theme.baseMargin / 3}rem;
  margin-bottom: ${({ theme }) => theme.baseMargin / 3}rem;
  ${media.md`
  font-size: 30px;
  position: relative;
  left: -15px;
  
`}
  ${media.lg`
    font-size: 35px;
`}
`;

function Logo() {
  return (
    <LogoLink to="/">
      <LogoImage src={logo} />
      <H1>Shiremoor Adventure Playground Trust</H1>
    </LogoLink>
  );
}

export default Logo;
