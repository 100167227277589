import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
}

function SEO(props: SEOProps) {
  const { pathname } = useLocation();
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);

  const { title, description, image, url } = {
    title: props.title === siteMetadata.title ? undefined : props.title,
    description: props.description || siteMetadata.description,
    image: `${siteMetadata.siteUrl}${props.image || siteMetadata.image}`,
    url: `${siteMetadata.siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={title}
      defaultTitle={siteMetadata.title}
      titleTemplate={`%s | ${siteMetadata.title}`}
    >
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      {url && <meta property="og:url" content={url} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
}

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
      }
    }
  }
`;
