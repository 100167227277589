import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { useStaticQuery, graphql, Link, GatsbyLinkProps } from "gatsby";
import Collapse from "./collapse";
import Logo from "../../logo";
import { Container, media } from "styled-bootstrap-grid";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalizePath } from "../../../utils/normalize-path";
import { useRouteChanged } from "./use-route-changed";
import wigglyLine from "./wiggly-line.svg";

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  display: flex;
  flex-direction: column;
  text-align: bottom;
  background-color: ${({ theme }) => theme.themeColor};
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  ${media.md`
    flex-direction: row;
    `}
`;

const HamburgerButton = styled.button`
  display: block;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  ${media.md`
  display: none;
`}
`;

const NavbarWrapper = styled(Container)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  ${media.md`
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
`}
`;
const LogoWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  & > * {
    flex-shrink: 1;
  }
`;

const StyledWigglyLine = styled((props) => <img src={wigglyLine} {...props} />)`
  width: 100%;
  height: 10px;
  bottom: -10px;
  position: absolute;

  ${media.md`
  height: 1.5vw;
  bottom: -1.5vw;
  `}
`;

const NavLink = styled(
  ({ $active, ref, ...props }: GatsbyLinkProps<any> & { $active: boolean }) => (
    <Link {...props} />
  )
)`
  margin-left: 0rem;
  margin-right: 0rem;
  text-decoration: none;
  color: ${({ theme }: any) => theme.headerColor};
  font-weight: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  ${({ theme, $active }: { theme: any; $active?: boolean }) => css`
    border-left: 0.5rem solid ${$active ? theme.primaryColor : "transparent"};
  `}

  transition: border-color 0.15s ease-in-out;

  ${media.md`
    padding-top: 0.1rem;
    border-left 0;
    ${({ theme, $active }: { theme: any; $active?: boolean }) => css`
      &:hover {
        border-top: 0.5rem solid
          ${$active ? theme.primaryColor : theme.menuItemHoverColor};
      }
      border-top: 0.5rem solid ${$active ? theme.primaryColor : "transparent"};
    `}
  `}
`;

function Navbar({ location }: any) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen((prevIsOpen) => !prevIsOpen), []);

  const data = useStaticQuery(graphql`
    query NavbarQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/(pages|home)/" } }
        sort: { order: ASC, fields: [frontmatter___position] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              menuItemLabel
              hidden
            }
          }
        }
      }
    }
  `);

  const menuItems = data.allMdx.edges.map(({ node }: any) => node.frontmatter);

  const closeMenu = useCallback(() => setIsOpen(false), []);

  useRouteChanged(closeMenu);
  const currentPath = normalizePath(location.pathname);

  return (
    <StyledHeader>
      <NavbarWrapper>
        <LogoWrapper>
          <Logo />
          <HamburgerButton onClick={toggle} title="Main menu">
            <FontAwesomeIcon icon={faBars} size="2x" />
          </HamburgerButton>
        </LogoWrapper>
        <Collapse isOpen={isOpen}>
          <StyledNav>
            {menuItems
              .filter(({ hidden }: any) => !hidden)
              .map(({ slug, menuItemLabel }: any) => {
                const path = normalizePath(slug);
                const active = currentPath === path;
                return (
                  <NavLink
                    onClick={closeMenu}
                    $active={active}
                    key={path}
                    to={path}
                  >
                    {menuItemLabel}
                  </NavLink>
                );
              })}
          </StyledNav>
        </Collapse>
      </NavbarWrapper>
      <StyledWigglyLine />
    </StyledHeader>
  );
}

export default Navbar;
